
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';
/**
 * @Description: 菜单
 * @author gan
 * @date: 2020/12/17 11:09
 * @attribute name 名称
 * @attribute path 路径
 * @attribute icon 图标
 * @attribute children 子菜单
 * @attribute menuKey 菜单key(需要唯一，用于获取权限)
 */
import {setConfigCharacters} from "@/utils/common";

export default {
  menu: [
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005193'),
      icon: '&#xe67b;',
      path: '/statistics',
      menuKey: 'yms_statistics',
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005496'),
      icon: '&#xe749;',
      type: 'productList',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002082'),
          path: '/productList',
          menuKey: 'yms_productList',
          children: [
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002517'),
              path: '/viewSku',
              menuKey: 'yms_viewSku',
              menuHide: true // 菜单不展示
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006587'),
              path: '/productDetails',
              menuKey: 'yms_productDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002664'),
          path: '/category',
          menuKey: 'yms_category',
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006588'),
          path: '/sizeTemplateManage',
          menuKey: 'yms_sizeTemplateManage',
          children: [
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006589'),
              path: '/updateSizeTemplate',
              menuKey: 'yms_updateSizeTemplate',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006590'),
          path: '/approvalList',
          menuKey: 'yms_approvalList',
          children: [
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006591'),
              path: '/approvalDetails',
              menuKey: 'yms_approvalDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002520'),
              path: '/findSimilarProducts',
              menuKey: 'yms_findSimilarProducts',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002093'),
          path: '/itemAttributes',
          menuKey: 'yms_itemAttributes'
        },
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001041'),
      icon: '&#xe665;',
      type: 'orderList',
      children: [
        {
          name: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001327')}`,
          path: '/orderList',
          menuKey: 'yms_orderList',
          children: [
            {
              name: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1004855')}`,
              path: '/orderListDetails',
              menuKey: 'yms_orderListDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006592'),
              path: '/supplierOrderDetails',
              menuKey: 'yms_supplierOrderDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006593'),
          path: '/distributorStockList',
          menuKey: 'yms_distributorStockList'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006594'),
          path: '/supplierStockList',
          menuKey: 'yms_supplierStockList',
          children: [
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006595'),
              path: '/supplierStockOrderDetails',
              menuKey: 'yms_supplierStockOrderDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002092'),
          path: '/supplierOrders',
          menuKey: 'yms_supplierOrders'
        },
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000018'),
      icon: '&#xe679;',
      type: 'wms',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002084'),
          path: '/wms/inventoryLevels',
          menuKey: 'yms_wms_inventoryLevels'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006596'),
          children: [
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006597'),
              path: '/billLadingManage',
              menuKey: 'yms_billLadingManage',
              children: [
                {
                  name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006598'),
                  path: '/billLadingManageDetails',
                  menuKey: 'yms_billLadingManageDetails',
                  menuHide: true // 菜单不展示
                }
              ]
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001822'),
              path: '/billWarehousing',
              menuKey: 'yms_billWarehousing'
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001823'),
              path: '/commoditySorting',
              menuKey: 'yms_commoditySorting'
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002083'),
              path: '/commoditySticker',
              menuKey: 'yms_commoditySticker'
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001826'),
              path: '/commodityShelves',
              menuKey: 'yms_commodityShelves'
            }
          ]
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005689'),
          children: [
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002428'),
              path: '/assembleGeneratePickingList',
              menuKey: 'yms_assembleGeneratePickingList'
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006599'),
              path: '/assembleGeneratePickingDetails',
              menuKey: 'yms_assembleGeneratePickingDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006592'),
              path: '/assembleSupplierOrderDetails',
              menuKey: 'yms_assembleSupplierOrderDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006600'),
              path: '/assemblePickingList',
              menuKey: 'yms_assemblePickingList'
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002393'),
              path: '/assemblePickingListDetails',
              menuKey: 'yms_assemblePickingListDetails',
              menuHide: true // 菜单不展示
            },
          ]
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001227'),
          children: [
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002428'),
              path: '/wms/generateOrderList',
              menuKey: 'yms_wms_generateOrderList'
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006599'),
              path: '/generateOrderDetails',
              menuKey: 'yms_generateOrderDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006592'),
              path: '/generateSupplierOrderDetails',
              menuKey: 'yms_generateSupplierOrderDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006600'),
              path: '/wms/pickList',
              menuKey: 'yms_wms_pickList'
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002393'),
              path: '/wms/pickListDetails',
              menuKey: 'yms_wms_pickListDetails',
              menuHide: true // 菜单不展示
            },
          ]
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005789'),
          children: [
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006601'),
              path: '/wms/sortOrderSetting',
              menuKey: 'yms_wms_sortOrderSetting'
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001827'),
              path: '/wms/sorting',
              menuKey: 'yms_wms_sorting'
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001829'),
              path: '/wms/packWorking',
              menuKey: 'yms_wms_packWorking'
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001830'),
              path: '/sortingOut',
              menuKey: 'yms_sortingOut'
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006602'),
              path: '/handoverSheetManage',
              menuKey: 'yms_handoverSheetManage',
              children: [
                {
                  name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006603'),
                  path: '/handoverSheetManageDetails',
                  menuKey: 'yms_handoverSheetManageDetails',
                  menuHide: true // 菜单不展示
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006604'),
      icon: '&#xe668;',
      type: 'supplierList',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006605'),
          path: '/supplierList',
          menuKey: 'yms_supplierList'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006606'),
          path: '/supplierAuditList',
          menuKey: 'yms_supplierAuditList'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006607'),
          path: '/supplierLevel',
          menuKey: 'yms_supplierLevel'
        }
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006608'),
      icon: '&#xe699;',
      type: 'distributorList',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006609'),
          path: '/customerList',
          menuKey: 'yms_customerList',
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002331'),
          path: '/customerLabel',
          menuKey: 'yms_customerLabel',
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002330'),
          path: '/customerGroups',
          menuKey: 'yms_customerGroups',
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002332'),
          path: '/customerGrade',
          menuKey: 'yms_customerGrade'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006610'),
          path: '/tuokeLink',
          menuKey: 'yms_tuokeLink'
        }
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001042'),
      icon: '&#xe668;',
      type: 'supplierBillManage',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006611'),
          path: '/supplierBillManage',
          menuKey: 'yms_supplierBillManage',
          children: [
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006612'),
              path: '/supplierBillDetails',
              menuKey: 'yms_supplierBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002094'),
          path: '/customerBill',
          menuKey: 'yms_customerBill',
          children: [
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006613'),
              path: '/customerBillDetails',
              menuKey: 'yms_customerBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002095'),
          path: '/logisticsBill',
          menuKey: 'yms_logisticsBill',
          children: [
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006614'),
              path: '/logisticsBillDetails',
              menuKey: 'yms_logisticsBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002096'),
          path: '/warehouseBill',
          menuKey: 'yms_warehouseBill',
          children: [
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006615'),
              path: '/warehouseBillDetails',
              menuKey: 'yms_warehouseBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002725'),
          path: '/financialJournal',
          menuKey: 'yms_financialJournal'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002089'),
          path: '/rechargeRecord',
          menuKey: 'yms_rechargeRecord'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006616'),
          path: '/passwordManage',
          menuKey: 'yms_passwordManage'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002088'),
          path: '/subsidyReview',
          menuKey: 'yms_subsidyReview'
        }
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006617'),
      icon: '&#xe68e;',
      type: 'logisticsManage',
      children: [
        {
          name: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1002896')}`,
          path: '/ymsLogistics',
          menuKey: 'yms_ymsLogistics'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006618'),
          path: '/basicLogistics',
          menuKey: 'yms_basicLogistics'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006619'),
          path: '/smartLogisticsRule',
          menuKey: 'yms_smartLogisticsRule'
        },
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001057'),
      icon: '&#xe68f;',
      type: 'warehouseManage',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006620'),
          path: '/warehouseManage',
          menuKey: 'yms_warehouseManage'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006621'),
          path: '/reservoirManage',
          menuKey: 'yms_reservoirManage'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006622'),
          path: '/locationManage',
          menuKey: 'yms_locationManage'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006623'),
          path: '/messageNotification',
          menuKey: 'yms_messageNotification'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001561'),
          path: '/exchangeRateConfig',
          menuKey: 'yms_exchangeRateConfig'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006624'),
          path: '/containerManage',
          menuKey: 'yms_containerManage'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006625'),
          path: '/containerSettings',
          menuKey: 'yms_containerSettings'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006626'),
          path: '/addressLibraryManage',
          menuKey: 'yms_addressLibraryManage'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006627'),
          path: '/processConfiguration',
          menuKey: 'yms_processConfiguration'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006628'),
          path: '/iossTaxRateSetting',
          menuKey: 'yms_iossTaxRateSetting'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000920'),
          children: [
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006629'),
              path: '/declarationRules',
              menuKey: 'yms_declarationRules'
            }
          ]
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001041'),
          children: [
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006630'),
              path: '/automaticDistributionRules',
              menuKey: 'yms_automaticDistributionRules'
            }
          ]
        }
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006631'),
      icon: '&#xe68b;',
      type: 'helpCenter',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000036'),
          path: '/helpCenter',
          menuKey: 'yms_helpCenter',
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006632'),
          path: '/questionnaireInvestigation',
          menuKey: 'yms_questionnaireInvestigation',
          children: [
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002029'),
              path: '/questionnaireStatistics',
              menuKey: 'yms_questionnaireStatistics',
              menuHide: true // 菜单不展示
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002123'),
              path: '/addQuestionnaire',
              menuKey: 'yms_addQuestionnaire',
              menuHide: true // 菜单不展示
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006633'),
              path: '/editQuestionnaire',
              menuKey: 'yms_editQuestionnaire',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006634'),
          path: '/operatingInstructions',
          menuKey: 'yms_operatingInstructions',
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000804'),
          id: 'toSystemAnnouncement',
          jurisdictionHide: true, // 不需要权限判断
          hideMenu: true, // 在权限设置中不展示此菜单
          externalLink: true, // 是否跳转到外部系统的标识
          menuKey: 'yms_systemAnnouncement',
        },
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006635'),
      icon: '&#xe691;',
      type: 'dataCenter',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002086'),
          path: '/deliveryDetails',
          menuKey: 'yms_deliveryDetails',
        }
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006636'),
      icon: '&#xe66a;',
      type: 'activityManage',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006637'),
          path: '/siteManage',
          menuKey: 'yms_siteManage',
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006638'),
          path: '/activityManage',
          menuKey: 'yms_activityManage',
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006639'),
          path: '/editActivity',
          menuKey: 'yms_editActivity',
          menuHide: true // 菜单不展示
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006640'),
          path: '/advertisingManage',
          menuKey: 'yms_advertisingManage',
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1003928'),
          path: '/rotationChart',
          menuKey: 'yms_rotationChart',
          menuHide: true // 菜单不展示
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1003895'),
          path: '/vajraDistrict',
          menuKey: 'yms_vajraDistrict',
          menuHide: true // 菜单不展示
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1003929'),
          path: '/topAdvertisingSpace',
          menuKey: 'yms_topAdvertisingSpace',
          menuHide: true // 菜单不展示
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1003930'),
          path: '/sideAdvertisingSpace',
          menuKey: 'yms_sideAdvertisingSpace',
          menuHide: true // 菜单不展示
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1003931'),
          path: '/infoAdvertisingSpace',
          menuKey: 'yms_infoAdvertisingSpace',
          menuHide: true // 菜单不展示
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002898'),
          path: '/subsidyRules',
          menuKey: 'yms_subsidyRules',
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006641'),
          path: '/distributorFeeAllocation',
          menuKey: 'yms_distributorFeeAllocation',
        },
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000285'),
      path: '/messageCenter',
      icon: '&#xe8be;',
      menuKey: 'yms_messageCenter',
      jurisdictionHide: true, // 不需要权限判断
      type: 'messageCenter',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006642'),
          path: '/messageMailboxes',
          menuKey: 'yms_messageMailboxes',
          menuHide: true // 菜单不展示
        },
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006643'),
      icon: '&#xe693;',
      path: '/employeeList',
      menuKey: 'yms_employeeList',
      type: 'employeeList',
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006644'),
      icon: '&#xe664;',
      type: 'importTask',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006645'),
          path: '/importTask',
          menuKey: 'yms_importTask'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006646'),
          path: '/exportTask',
          menuKey: 'yms_exportTask'
        }
      ]
    }
  ]
};
