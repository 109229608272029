<template>
  <Modal v-model="productRejectionNoticeModal" :title="$t('key1000895')" :width="800" :transfer="false" :mask-closable="false"
    @on-visible-change="productRejectionNoticeChange">
    <h2 class="mb15">{{ $t('key1000896') }}</h2>
    <div class="table_box">
      <Table
        highlight-row
        border
        max-height="550"
        :loading="tableLoading"
        :columns="tableColumns"
        :data="tableData">
      </Table>
    </div>
    <template #footer>
      <Button @click="productRejectionNoticeModal = false">{{ $t('key1000897') }}</Button>
      <Button type="primary" :disabled="tableData.length <=0" @click="productRejectionNoticeBtn">{{ $t('key1000138') }}</Button>
    </template>
  </Modal>
</template>

<script>
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import {isJSON, verifyLoginTicket, setConfigCharacters, commonCopyContent} from "@/utils/common";

export default {
  name: 'productRejectionNoticeModal',
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      productRejectionNoticeModal: false,
      tableColumns: [
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000787'),
          key: 'commodityInfo',
          align: 'left',
          minWidth: 300,
          render(h, params) {
            let name = params.row.name;
            let supplierSpu = params.row.supplierSpu;
            let ymsSpu = params.row.ymsSpu;
            let list = [
              v.tableImg(h, params, 'primaryImage'),
              h('div', {
                class: 'text-align-left pl10'
              }, [
                h('Alink', {
                  props: {
                    AlinkShow: false,
                    linkName: name,
                    AlinkClass: 'ellipsis_1'
                  }
                }),
                h('p', {class: 'flex align-items-center'}, [
                  h('span', 'SPU：' + supplierSpu),
                  h('Icon', {
                    props: {
                      type: 'ios-copy-outline',
                      size: 18,
                      color: '#01a6ee'
                    },
                    class: 'cursor ml6',
                    attrs: {
                      title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000000')
                    },
                    on: {
                      click: () => {
                        commonCopyContent(supplierSpu, alias1bddc4d174174d47a6dfb0437dace856.t('key1000898'));
                      }
                    }
                  })
                ]),
                ymsSpu ? h('p', {class: 'flex align-items-center'}, [
                  h('span', `${setConfigCharacters}SPU：` + ymsSpu),
                  h('Icon', {
                    props: {
                      type: 'ios-copy-outline',
                      size: 18,
                      color: '#01a6ee'
                    },
                    class: 'cursor ml6',
                    attrs: {
                      title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000000')
                    },
                    on: {
                      click: () => {
                        commonCopyContent(ymsSpu, alias1bddc4d174174d47a6dfb0437dace856.t('key1000898'));
                      }
                    }
                  })
                ]) : '',
              ])
            ];
            return h('div', {
              class: 'flex ptb10',
            }, list)
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000412'),
          key: 'shelvedRejectReason',
          align: 'left',
          width: 300,
          render(h, params) {
            let shelvedRejectReason = params.row.shelvedRejectReason;
            if (shelvedRejectReason) {
              return v.checkContentOverflow(h, params.row.shelvedRejectReason, 300, 2);
            } else {
              return h('span', '-');
            }
          }
        },
      ],
      tableData: [],
      productRejectionNoticeTimer: null,
      isPageVisible: true, // 默认是活跃状态
    }
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  created() {
    this.handleProductRejectionNotice();
    verifyLoginTicket().then((val) => {
      if (val) {
        this.getProductRejectionNoticeData();
      }
    });
  },
  methods: {
    // 判断当前页面标签是否处于活跃状态
    handleVisibilityChange() {
      this.isPageVisible = !document.hidden;
    },
    // 获取商品驳回通知的数据
    getProductRejectionNoticeData() {
      this.tableData = [];
      this.axios.get(api.get_ymsSupplierStationNotification_getProductReject).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas || [];
          let newList = [];
          if (data.length > 0) {
            newList = data.map((item) => {
              let obj = {};
              let content = item.content;
              if (isJSON(content)) {
                obj = JSON.parse(content);
              }
              return {
                ...obj,
                ymsStationNotificationId: item.ymsStationNotificationId
              }
            });
            let productGoodsIds = [];
            if (newList.length > 0) {
              newList.map((item) => {
                productGoodsIds.push(...item.productGoodsIds);
              });
              let list = this.uniqueFunc(productGoodsIds);
              if (list.length > 0) {
                this.getProductInfoByIds(list, newList, false, true,
                  false, api.post_productInfo_queryByProductGoodsIds, 'supplier').then((newData) => {
                  if (newData.length > 0) {
                    newList.map((item) => {
                      newData.map((ele) => {
                        if (item.productId === ele.productId) {
                          item.name = ele.name;
                          item.primaryImage = ele.primaryImage;
                          item.ymsSpu = ele.ymsSpu;
                          item.supplierSpu = ele.spu;
                        }
                      })
                    });
                    this.tableData = newList;
                    this.productRejectionNoticeModal = true;
                  }
                });
              }
            }
          }
        }
      });
    },
    // 定时任务，每隔10分钟调接口查询未读的商品驳回通知消息的数据
    handleProductRejectionNotice() {
      let v = this;
      if (['/login', '/logout'].includes(v.$route.path)) {
        clearInterval(v.productRejectionNoticeTimer);
      } else {
        v.productRejectionNoticeTimer = setInterval(() => {
          if (v.isPageVisible) {
            v.getProductRejectionNoticeData();
          }
        }, 10 * 60 * 1000)
      }
    },

    // 标记驳回通知已读
    productRejectionNoticeBtn() {
      let v = this;
      let ymsStationNotificationIds = [];
      v.tableData.map((item) => {
        ymsStationNotificationIds.push(item.ymsStationNotificationId);
      });
      v.axios.post(api.post_ymsSupplierStationNotification_batchRead, ymsStationNotificationIds, {loading: true}).then(response => {
        if (response.data.code === 0) {
          v.productRejectionNoticeModal = false;
        }
      });
    },
    // 监听弹窗
    productRejectionNoticeChange(value) {
      if (!value) {
        this.tableData = [];
      }
    }
  }
};
</script>

<style lang="less">

</style>
