
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';
export default {
  // 运营管理
  operationManage: [
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005193'),
      icon: '&#xe67b;',
      path: '/statistics',
      menuKey: 'supplier_statistics',
      hideCrumbs: true, // 隐藏面包屑
      guidanceModule: false, // 指引模块（默认开启）  true：开启指引模块    false  不开启指引模块
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006647'),
      icon: '&#xe668;',
      type: 'supplierOrder',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006648'),
          path: '/supplierOrderList',
          menuKey: 'supplier_supplierOrderList',
          guidanceModule: true
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1004416'),
          path: '/stockList',
          menuKey: 'supplier_stockList',
          guidanceModule: true,
          children: [
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005430'),
              path: '/initiateStocking',
              menuKey: 'supplier_initiateStocking',
              menuHide: true // 菜单不展示
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006649'),
              path: '/stockOrderDetails',
              menuKey: 'supplier_stockOrderDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006650'),
          path: '/shipmentRecord',
          menuKey: 'supplier_shipmentRecord',
          guidanceModule: true,
          children: [
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006651'),
              path: '/shipmentRecordDetails',
              menuKey: 'supplier_shipmentRecordDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005418'),
              path: '/additionalGoods',
              menuKey: 'supplier_additionalGoods',
              menuHide: true // 菜单不展示
            }
          ]
        }
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006652'),
      icon: '&#xe678;',
      type: 'productCenter',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005496'),
          path: '/productCenter/productGoods',
          menuKey: 'supplier_productCenter_productGoods',
          guidanceModule: true,
          children: [
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002517'),
              path: '/viewSku',
              menuKey: 'yms_viewSku',
              menuHide: true // 菜单不展示
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006653'),
              path: '/productDetails',
              menuKey: 'supplier_productDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005543'),
              path: '/collectProducts',
              menuKey: 'yms_collectProducts',
              menuHide: true // 菜单不展示
            },
          ]
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006654'),
          path: '/productCenter/productBrand',
          menuKey: 'supplier_productCenter_productBrand',
          guidanceModule: true,
        }
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006655'),
      icon: '&#xe68c;',
      type: 'platformAuthorization',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006656'),
          path: '/platformAuthorization',
          menuKey: 'supplier_platformAuthorization',
          guidanceModule: true,
        }
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006657'),
      path: '/reconciliationManage',
      menuKey: 'supplier_reconciliationManage',
      icon: '&#xe665;',
      type: 'reconciliationManage',
      guidanceModule: true,
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1003367'),
          path: '/reconciliationBillDetails',
          menuKey: 'supplier_reconciliationBillDetails',
          menuHide: true // 菜单不展示
        }
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000285'),
      path: '/messageCenter',
      menuKey: 'supplier_messageCenter',
      jurisdictionHide: true, // 不需要权限判断
      icon: '&#xe8be;',
      type: 'messageCenter',
      guidanceModule: true,
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006642'),
          path: '/messageMailboxes',
          menuKey: 'supplier_messageMailboxes',
          menuHide: true // 菜单不展示
        },
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006658'),
      icon: '&#xe6e3;',
      type: 'accountManage',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000596'),
          path: '/accountManage/basicInformation',
          menuKey: 'supplier_accountManage_basicInformation',
          guidanceModule: true,
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006643'),
          path: '/accountManage/management',
          menuKey: 'supplier_accountManage_management',
          guidanceModule: true,
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006659'),
          path: '/accountManage/collectionAccount',
          menuKey: 'supplier_accountManage_collectionAccount',
          guidanceModule: true,
        }
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006644'),
      icon: '&#xe664;',
      type: 'importExportTask',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006645'),
          path: '/importTask',
          menuKey: 'supplier_importTask'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006646'),
          path: '/exportTask',
          menuKey: 'supplier_exportTask'
        }
      ]
    }
  ],
  // 仓储管理
  warehouseManage: [
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002463'),
      path: '/wms/searchPackage',
      menuKey: 'supplier_wms_searchPackage',
      icon: '&#xe665;',
      type: 'searchPackage',
      guidanceModule: true,
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006660'),
          path: '/wms/searchPackageDetails',
          menuKey: 'supplier_wms_searchPackageDetails',
          menuHide: true // 菜单不展示
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005870'),
          path: '/addManualSupplierOrder',
          menuKey: 'supplier_addManualSupplierOrder',
          menuHide: true // 菜单不展示
        }
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006661'),
      path: '/wms/packingManage',
      menuKey: 'supplier_wms_packingManage',
      icon: '&#xe61b;',
      type: 'packingManage',
      guidanceModule: true,
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000539'),
          path: '/wms/exwarehouse',
          menuKey: 'supplier_wms_exwarehouse',
          menuHide: true, // 菜单不展示
          children: [
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005725'),
              path: '/wms/scanEx',
              menuKey: 'supplier_wms_scanEx',
              menuHide: true // 菜单不展示
            }
          ]
        },
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002084'),
      path: '/wms/inventoryLevels',
      menuKey: 'supplier_wms_inventoryLevels',
      icon: '&#xe65f;',
      type: 'inventoryLevels',
      guidanceModule: true,
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006662'),
      icon: '&#xe63c;',
      type: 'orderPicking',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002428'),
          guidanceModule: true,
          path: '/wms/generateOrderList',
          menuKey: 'supplier_wms_generateOrderList'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006599'),
          path: '/wms/generateOrderDetails',
          menuKey: 'supplier_wms_generateOrderDetails',
          menuHide: true // 菜单不展示
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006600'),
          guidanceModule: true,
          path: '/wms/pickList',
          menuKey: 'supplier_wms_pickList'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002393'),
          path: '/wms/pickListDetails',
          menuKey: 'supplier_wms_pickListDetails',
          menuHide: true // 菜单不展示
        },
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006663'),
      icon: '&#xe630;',
      type: 'outboundPperation',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006664'),
          path: '/wms/sortOrderSetting',
          menuKey: 'supplier_wms_sortOrderSetting',
          guidanceModule: true,
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001827'),
          path: '/wms/sorting',
          menuKey: 'supplier_wms_sorting',
          guidanceModule: true,
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005852'),
          path: '/wms/packWorking',
          menuKey: 'supplier_wms_packWorking',
          guidanceModule: true,
        }
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006644'),
      icon: '&#xe664;',
      type: 'importExportTask',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006645'),
          path: '/importTask',
          menuKey: 'supplier_importTask'
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006646'),
          path: '/exportTask',
          menuKey: 'supplier_exportTask'
        }
      ]
    }
  ],
};
