
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';
/**
 * @Description: 分销商后台管理系统--菜单
 * @author jing
 * @date 2021/1/19 9:49
 * @attribute name 名称
 * @attribute path 路径
 * @attribute icon 图标
 * @attribute children 子菜单
 * @attribute menuKey 菜单key(需要唯一，用于获取权限)
 * @attribute distributorType 分销商类型(ALIGJ: 阿里国际分销、YMS：云卖分销商)
 */
import {setConfigCharacters} from "@/utils/common";

export default {
  menu: [
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006666'),
      icon: '&#xe6b2;',
      path: '/distributionMarket',
      menuKey: 'distributor_distributionMarket',
      distributorType: ['ALIGJ']
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005496'),
      icon: '&#xe618;',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000795'),
          path: '/myInventory',
          menuKey: 'distributor_myInventory',
          distributorType: ['YMS']
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006667'),
          path: '/commodityList',
          menuKey: 'distributor_commodityList',
          distributorType: ['YMS', 'ALIGJ']
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006668'),
          path: '/generateSalesRecords',
          menuKey: 'distributor_generateSalesRecords',
          distributorType: ['YMS', 'ALIGJ']
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000008'),
          type: 'toListing',
          jurisdictionHide: true, // 不需要权限判断
          hideMenu: true, // 在角色管理中不展示此菜单
          externalLink: true, // 是否跳转到外部系统的标识
          menuKey: 'distributor_shelfTools',
          distributorType: ['YMS', 'ALIGJ']
        }
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006669'),
      icon: '&#xe6fe;',
      path: '/automaticRules',
      menuKey: 'distributor_automaticRules',
      distributorType: ['YMS', 'ALIGJ']
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006670'),
      icon: '&#xe604;',
      path: '/platformStoreList',
      menuKey: 'distributor_platformStoreList',
      distributorType: ['YMS', 'ALIGJ']
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000078'),
      icon: '&#xe63a;',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006671'),
          path: '/collectionSupplier',
          menuKey: 'distributor_collectionSupplier',
          distributorType: ['YMS']
        }
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001041'),
      icon: '&#xe668;',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1004415'),
          path: '/platformOrder',
          menuKey: 'distributor_platformOrder',
          distributorType: ['YMS', 'ALIGJ'],
          children: [
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006672'),
              path: '/platformDetails',
              menuKey: 'distributor_platformDetails',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006673'),
              path: '/addTemuOrder',
              menuKey: 'distributor_addTemuOrder',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001327')}`,
          path: '/myOrder',
          menuKey: 'distributor_myOrder',
          distributorType: ['YMS'],
          children: [
            {
              name: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1004855')}`,
              path: '/myOrderDetails',
              menuKey: 'distributor_myOrderDetails',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1004877'),
              path: '/addCloudSalesOrder',
              menuKey: 'distributor_addCloudSalesOrder',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1004416'),
          path: '/distributorStockList',
          menuKey: 'distributor_distributorStockList',
          distributorType: ['YMS'],
          children: [
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001369'),
              path: '/createBackupOrder',
              menuKey: 'distributor_createBackupOrder',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006649'),
              path: '/distributorStockListDetails',
              menuKey: 'distributor_distributorStockListDetails',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            }
          ]
        }
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001042'),
      icon: '&#xe645;',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002089'),
          path: '/rechargeRecord',
          menuKey: 'distributor_rechargeRecord',
          distributorType: ['YMS'],
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002725'),
          path: '/financialJournal',
          menuKey: 'distributor_financialJournal',
          distributorType: ['YMS'],
        },
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006617'),
      icon: '&#xe68e;',
      children: [
        {
          name: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1002896')}`,
          path: '/logisticsSettings',
          menuKey: 'distributor_logisticsSettings',
          distributorType: ['YMS'],
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001079'),
          path: '/ownLogistics',
          menuKey: 'distributor_ownLogistics',
          distributorType: ['YMS'],
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006619'),
          path: '/smartRule',
          menuKey: 'distributor_smartRule',
          distributorType: ['YMS'],
        }
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006674'),
      icon: '&#xe61b;',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006675'),
          path: '/roleManage',
          menuKey: 'distributor_roleManage',
          distributorType: ['YMS', 'ALIGJ'],
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006676'),
          path: '/userManage',
          menuKey: 'distributor_userManage',
          distributorType: ['YMS', 'ALIGJ'],
        },
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000285'),
      path: '/newsList',
      menuKey: 'distributor_newsList',
      distributorType: ['YMS', 'ALIGJ'],
      menuHide: true,
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006677'),
          path: '/newsListDetails',
          menuKey: 'distributor_newsListDetails',
          distributorType: ['YMS', 'ALIGJ'],
          menuHide: true
        }
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006678'),
      icon: '&#xe627;',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000596'),
          path: '/basicInformation',
          menuKey: 'distributor_basicInformation',
          distributorType: ['YMS', 'ALIGJ'],
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006679'),
          path: '/securityInformation',
          menuKey: 'distributor_securityInformation',
          distributorType: ['YMS', 'ALIGJ'],
          children: [
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1004376'),
              path: '/modifyMobile',
              menuKey: 'distributor_modifyMobile',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1004378'),
              path: '/modifyMailbox',
              menuKey: 'distributor_modifyMailbox',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            },
            {
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1004620'),
              path: '/modifyPassword',
              menuKey: 'distributor_modifyPassword',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1004319'),
          path: '/iossManageList',
          menuKey: 'distributor_iossManageList',
          distributorType: ['YMS'],
        },
      ]
    },
    {
      name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006644'),
      icon: '&#xe6a9;',
      type: 'importTask',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006646'),
          path: '/exportTask',
          menuKey: 'distributor_exportTask',
          distributorType: ['YMS', 'ALIGJ'],
        }
      ]
    },
    {
      name: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1006665')}`,
      type: 'ymsShopping',
      children: [
        {
          name: alias1bddc4d174174d47a6dfb0437dace856.t('key1006680'),
          menuKey: 'shop_shopList',
          distributorType: ['YMS'],
        }
      ]
    }
  ]
};
